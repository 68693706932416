@font-face {
  font-family: 'Druk Cyr';
  src: local('Druk Cyr Bold Italic'), local('DrukCyr-BoldItalic'),
  url('DrukCyr-BoldItalic.woff2') format('woff2'),
  url('DrukCyr-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Druk Cyr';
  src: local('Druk Cyr Heavy Italic'), local('DrukCyr-HeavyItalic'),
  url('DrukCyr-HeavyItalic.woff2') format('woff2'),
  url('DrukCyr-HeavyItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Regular'), local('Inter-Regular'),
  url('Inter-Regular.woff2') format('woff2'),
  url('Inter-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Medium'), local('Inter-Medium'),
  url('Inter-Medium.woff2') format('woff2'),
  url('Inter-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Road Rage(RUS BY LYAJKA)';
  src: local('ljk_RoadRage'),
  url('roadrage.ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
